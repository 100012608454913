import { useState, useEffect } from "react";
import { moneyFormatter } from "../../../utils";

const ValueGoal = ({ currentProject, goal, apiKey, dateInit, dateEnd }) => {
  const [numValue, setNumValue] = useState(undefined);

  useEffect(() => {
    if (currentProject && currentProject.apiKey) {
      if (currentProject.apiKey.toLowerCase().indexOf("manual") >= 0) {
        setNumValue(moneyFormatter(currentProject?.recEnd));
      } else {
        setNumValue(undefined);
      }
    }
  }, [currentProject]);

  return (
    <div className="objectives-help-bg bg-1">
      <div className="objectives-help-title">
        Objetivo de AYUDA - {currentProject?.goal} &euro;
      </div>
      {typeof numValue !== "undefined" ? (
        <div className="objectives-help-num">
          <div className="objectives-help-num_content">{numValue}</div>
        </div>
      ) : (
        <div className="iframe-container">
          <iframe
            width="100%"
            height="100%"
            title="Farmacia"
            src={`https://farmasolidaria-donaciones.azurewebsites.net/api/GetDonationsByPharmacy?code=BlangQixO6QanaKeFjQmyB1DVnDA2e7qVJXin8z/Y6Sa7SWgAiVrQg==&apikey=${currentProject?.apiKey}&datefrom=${currentProject?.dateInit}%2000:00:00&dateto=${currentProject?.dateEnd}%2023:59:59`}
          ></iframe>
        </div>
      )}
    </div>
  );
};
export default ValueGoal;
