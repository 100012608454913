import { useState, useEffect, useCallback } from "react";
import useFarmacia from "./api/farmacia";
import useProject from "./api/project";
import { storage } from "./utils";
import useWakeLock from "./utils/hooks/useWakeLock";
import Login from "./views/login";
import Farmacia from "./views/farmacia";

let isFetchedFarmacia = false;
let isStoredFarmacia = false;
let isFetchedProjects = false;

function App() {
  const [id_key_farmacia, set_id_key_farmacia] = useState("");

  const [fetchFarmacia, farmacia, loadingFarmacia, errorMessageFarmacia] =
    useFarmacia();
  const [fetchProjects, projects, loadingProjects, errorMessageProjects] =
    useProject();

  const [requestWakeLock] = useWakeLock();

  useEffect(() => {
    const store = storage.get();
    if (!isFetchedFarmacia && store && store.id_farmacia) {
      isFetchedFarmacia = true;
      set_id_key_farmacia(store.id_farmacia);
      fetchFarmacia(store.id_farmacia);
    }
  }, [fetchFarmacia]);

  useEffect(() => {
    if (farmacia && farmacia.id) {
      if (!isStoredFarmacia) {
        isStoredFarmacia = true;
        storage.set({ id_farmacia: id_key_farmacia });
      }

      if (!isFetchedProjects) {
        isFetchedProjects = true;
        fetchProjects(farmacia.id);
      }
    }
  }, [fetchProjects, farmacia, id_key_farmacia]);

  const getFarmacia = useCallback(() => {
    if (!loadingFarmacia) {
      fetchFarmacia(id_key_farmacia);
    }
  }, [fetchFarmacia, loadingFarmacia, id_key_farmacia]);

  return !farmacia || !projects ? (
    <Login
      id_key_farmacia={id_key_farmacia}
      set_id_key_farmacia={set_id_key_farmacia}
      getFarmacia={getFarmacia}
      loading={loadingFarmacia || loadingProjects}
      errorMessage={errorMessageFarmacia || errorMessageProjects}
      requestWakeLock={requestWakeLock}
    />
  ) : (
    <Farmacia farmacia={farmacia} projects={projects} />
  );
}

export default App;
