import { useState, useCallback } from "react";

const useWakeLock = () => {
  const [wakeLockObj, setWakeLockObj] = useState(null);
  const [wakeLockStatus, setWakeLockStatus] = useState(false);

  const requestWakeLock = useCallback(async () => {
    if ("wakeLock" in window.navigator) {
      try {
        const wakeLock = await window.navigator.wakeLock.request("screen");

        // change up our interface to reflect wake lock active
        setWakeLockObj(wakeLock);
        setWakeLockStatus(true);
      } catch (err) {
        // if wake lock request fails - usually system related, such as battery
        console.log("wakeLock no soportado en este navegador");
      }
    }
  }, []);

  const releaseWakeLock = useCallback(async () => {
    if (wakeLockObj) {
      wakeLockObj.release().then(() => {
        setWakeLockObj(null);
        setWakeLockStatus(false);
      });
    }
  }, [wakeLockObj]);

  return [requestWakeLock, releaseWakeLock, wakeLockStatus];
};

export default useWakeLock;
