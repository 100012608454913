import farmasolidariaSrc from "../../assets/img/farmasolidaria-logo.webp";

const Header = ({ farmacia }) => {
  return (
    <header className="main-single-header px-3 pt-3 pb-md-3 pb-0">
      <div className="row g-0 align-items-center justify-content-between">
        <div className="col-md-2 col-6 order-1">
          <div className="logo-farmacia">
            <img src={farmacia.logo} alt={farmacia.name} />
          </div>
        </div>
        <div className="col-md-8 col-12 order-md-2 order-3 pt-md-0 pt-3">
          <h2 className="lema">
            "Nos estamos volcando para AYUDAR a las personas del BARRIO. ¿NOS
            AYUDAS?"
          </h2>
        </div>
        <div className="col-md-2 col-3 order-md-3 order-2">
          <img
            src={farmasolidariaSrc}
            alt=""
            className="responsive ms-md-0 ms-auto"
          />
        </div>
      </div>
    </header>
  );
};
export default Header;
