import { get_fecha, moneyFormatter } from "../../utils";

const Projects = ({ finishedProjects }) => {
  return finishedProjects ? (
    <div className="project-list">
      {finishedProjects.map((project, k) => {
        const fechaParsed = get_fecha(project.dateEnd);
        return (
          <div className="project-success mb-md-0 mb-3" key={k}>
            <div className="row g-0 align-items-center">
              <div className="col-md-5 col-12 mb-md-0 mb-2">
                <div className="project-success-tag">
                  CONSEGUIDO
                  {fechaParsed
                    ? ` en ${fechaParsed.month}'${fechaParsed.year}`
                    : null}
                </div>
              </div>
              <div className="col-md-2 col-3 px-3">
                {project.logo ? (
                  <img
                    src={project.logo}
                    alt="Banco de alimentos"
                    className="responsive-full"
                  />
                ) : (
                  <div className="project-success-name">{project.entity}</div>
                )}
              </div>
              <div className="col-md-5 col-9">
                <p className="project-desc-sec">
                  {project.description}
                  <br />
                  {project.recEnd !== "" ? (
                    <>
                      Recaudados{" "}
                      <span className="euros">
                        {moneyFormatter(project.recEnd)}
                      </span>
                    </>
                  ) : null}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};
export default Projects;
