import { useState, useEffect } from "react";
import { get_fecha } from "../../../utils";

const twoDecimals = (num) => {
  return num < 10 ? "0" + num : num;
};

const Timer = ({ value }) => {
  const [status, setStatus] = useState(null);
  const [fecha, setFecha] = useState(null);

  useEffect(() => {
    let timer = null;
    if (value && typeof value === "string" && value.indexOf("-") >= 0) {
      const fechaParsed = get_fecha(value);
      setFecha(fechaParsed);

      var countDownDate = new Date(
        fechaParsed.year,
        fechaParsed.monthNum - 1,
        fechaParsed.day,
        23,
        59,
        59
      ).getTime();

      timer = setInterval(() => {
        const now = new Date().getTime();
        const timeleft = countDownDate - now;

        if (timeleft >= 0) {
          const T = {
            days: Math.floor(timeleft / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((timeleft % (1000 * 60)) / 1000),
          };
          setStatus(T);
        } else {
          setStatus(null);
        }
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [value]);

  return (
    <div className="objectives-help-bg bg-2">
      <div className="px-md-0 px-4 pb-md-0 pb-5">
        <div className="objectives-help-title mb-md-3 mb-4">
          Fecha límite - {fecha ? `${fecha.day} de ${fecha.month}` : ""}
        </div>
        <div className="counter">
          {status ? (
            <div className="counter_row">
              <div className="counter_col">
                <div className="counter_num counter_num_days">
                  {status.days}
                </div>
                <div className="counter_text">{`Día${
                  status.days === 1 ? "" : "s"
                }`}</div>
              </div>
              <div className="counter_col">
                <div className="counter_num counter_num_hours">
                  {twoDecimals(status.hours)}
                </div>
                <div className="counter_text">{`Hora${
                  status.hours === 1 ? "" : "s"
                }`}</div>
              </div>
              <div className="counter_col">
                <div className="counter_num counter_num_minutes">
                  {twoDecimals(status.minutes)}
                </div>
                <div className="counter_text">{`Minuto${
                  status.minutes === 1 ? "" : "s"
                }`}</div>
              </div>
              <div className="counter_col">
                <div className="counter_num counter_num_seconds">
                  {twoDecimals(status.seconds)}
                </div>
                <div className="counter_text">{`Segundo${
                  status.seconds === 1 ? "" : "s"
                }`}</div>
              </div>
            </div>
          ) : (
            <div className="counter_timesup">Fecha cumplida</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timer;
