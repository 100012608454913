import { useState, useEffect } from "react";
import Header from "./header";
import Title from "./title";
import Goal from "./goal";
import CertDonation from "./cert_donation";
import Projects from "./projects";

const FarmaciaView = ({ farmacia, projects }) => {
  const [currentProject, setCurrentProject] = useState(null);
  const [finishedProjects, setFinishedProjects] = useState(null);

  useEffect(() => {
    if (projects?.length) {
      const currentProjects = projects.filter((project) => {
        return project.status.toLowerCase() === "en marcha";
      });
      const newFinishedProjects = projects
        .filter((project) => {
          return project.status.toLowerCase() === "finalizado";
        })
        .sort((a, b) => {
          return a.dateEnd > b.dateEnd ? -1 : 1;
        });
      if (currentProjects[0]) {
        setCurrentProject(currentProjects[0]);
      }
      if (newFinishedProjects.length) {
        setFinishedProjects(newFinishedProjects);
      }
    }
  }, [projects]);

  return (
    <div className="farmasolidaria-container">
      <Header farmacia={farmacia} />
      <Title currentProject={currentProject} />
      <Goal currentProject={currentProject} />
      <CertDonation currentProject={currentProject} />
      <Projects finishedProjects={finishedProjects} />
    </div>
  );
};

export default FarmaciaView;
