import { useState, useCallback } from "react";
import Airtable from "airtable";
import airtableConfig from "../config";

var baseFarmacia = new Airtable({ apiKey: airtableConfig.apiKey }).base(
  airtableConfig.farmacia.db
);

const useFarmacia = () => {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const fetchData = useCallback((id_key) => {
    const {
      table,
      view,
      parameterName,
      errorMessage,
      fields,
      fieldsToParameters,
    } = airtableConfig.farmacia;

    setDataLoading(true);
    setErrorMessage(null);

    baseFarmacia(table)
      .select({
        maxRecords: 1,
        view,
        filterByFormula: `{${parameterName}}='${id_key}'`,
        fields,
      })
      .eachPage(
        function page(records) {
          // setData(records[0].fields);
          if (records[0]) {
            setData(fieldsToParameters(records[0].fields));
          } else {
            setErrorMessage(errorMessage);
          }
          setDataLoading(false);
        },
        function done(err) {
          setDataLoading(false);
          if (err) {
            setErrorMessage(errorMessage);
            return;
          }
        }
      );
  }, []);

  return [fetchData, data, dataLoading, errorMessage];
};
export default useFarmacia;
