import { useState } from "react";
import { Button, Form } from "reactstrap";
import farmasolidariaSrc from "../../assets/img/farmasolidaria-logo.webp";

const Login = ({
  id_key_farmacia,
  set_id_key_farmacia,
  getFarmacia,
  loading,
  errorMessage,
  requestWakeLock,
}) => {
  const [isInputError, setIsInputError] = useState(false);

  return (
    <>
      <div className="login-screen">
        <div className="login-centered">
          <div className="login-container">
            <div className="login-logo">
              <img src={farmasolidariaSrc} alt="FarmaSOLIDARIA" />
            </div>
            <div className="login-title">
              Bienvenido a la App de FarmaSOLIDARIA.
              <br />
              Introduce a continuación el código de 4 dígitos que se te ha
              proporcionado.
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                if (id_key_farmacia.length !== 4) {
                  setIsInputError(true);
                } else {
                  setIsInputError(false);
                  getFarmacia();
                }
              }}
            >
              <div className="login-input-line">
                <div
                  className={
                    "login-input" +
                    (isInputError || errorMessage ? " login-input-error" : "")
                  }
                >
                  <div className="login-input_graph">
                    <div className="login-input_graph-cell" />
                    <div className="login-input_graph-cell" />
                    <div className="login-input_graph-cell" />
                    <div className="login-input_graph-cell" />
                  </div>
                  <div className="login-input_container">
                    <input
                      value={id_key_farmacia}
                      type="text"
                      className={id_key_farmacia.length >= 4 ? "not-caret" : ""}
                      onChange={(e) => {
                        setIsInputError(false);
                        set_id_key_farmacia(
                          e.target.value.substring(0, 4).toUpperCase()
                        );
                      }}
                    />
                  </div>
                </div>

                {isInputError || errorMessage ? (
                  <div className="text-danger text-center pt-2">
                    {errorMessage || "Por favor, introduce un código válido"}
                  </div>
                ) : null}
              </div>
              <div className="text-center">
                <Button
                  color="primary"
                  type="submit"
                  size="lg"
                  onClick={requestWakeLock}
                >
                  Ingresar
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="login-footer">
          <span className="text-secondary">¿No recuerdas tu código?</span>{" "}
          Llamanos al tel. 91 999 31 81 y te ayudaremos.
        </div>
      </div>
      {loading ? (
        <div className="loading-screen">
          <div className="loading-screen_graph-container">
            <div className="loading-screen_graph" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Login;
