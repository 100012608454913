//import ASMEGUSrc from "../../assets/img/ASMEGU.png";

import classNames from "classnames";

const Title = ({ currentProject }) => {
  return currentProject ? (
    <div className="project">
      <div className="row g-0 align-items-center justify-content-center">
        {currentProject.logo || currentProject.entity ? (
          <div className="col-md-2">
            <div className="row g-0 align-items-center justify-content-center py-md-0 py-2 px-md-0 px-3">
              {currentProject.logo ? (
                <div className="col-md-12 col-4">
                  <div className="px-md-4 px-0">
                    <img
                      src={currentProject.logo}
                      alt="Asociación de Salud Mental de Guardamar"
                      className="responsive mx-auto"
                    />
                  </div>
                </div>
              ) : null}
              {/* {currentProject.entity ? (
                <div className="col-md-12 col-5">
                  <div className="proyecto_actual_nombre">
                    {currentProject.entity}
                  </div>
                </div>
              ) : null} */}
            </div>
          </div>
        ) : null}

        <div
          className={classNames("col", {
            "col-md-10": currentProject.logo || currentProject.entity,
          })}
        >
          <p className="project-desc px-md-2 px-5">
            {currentProject.description}
          </p>
        </div>
        {/* <div className="col-auto ps-3 d-md-block d-none">
          <img
            src={ASMEGUSrc}
            alt="Asociación de Salud Mental de Guardamar"
            className="responsive"
          />
        </div> */}
      </div>
    </div>
  ) : null;
};
export default Title;
