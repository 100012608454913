export const get_fecha = (value) => {
  if (!value || value === "") {
    return null;
  }

  const dataDayArray = value.split("-").map((s) => {
    return parseInt(s, 10);
  });

  const year = dataDayArray[0];
  const monthNum = dataDayArray[1];
  const day = dataDayArray[2];

  const monthList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return { day, monthNum, month: monthList[monthNum - 1], year };
};

const storageName = "farmasolidariaStore";

export const storage = {
  get: () => {
    if (window) {
      const dataString = window.localStorage.getItem(storageName);
      if (dataString) {
        return JSON.parse(dataString);
      }
    }
    return null;
  },
  set: (data) => {
    if (window && data) {
      window.localStorage.setItem(storageName, JSON.stringify(data));
    }
  },
  clear: () => {
    if (window) {
      window.localStorage.removeItem(storageName);
    }
  },
};

export const moneyFormatter = (num) => {
  if (typeof num === "number") {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "EUR",
    }).format(num);
  } else {
    return num;
  }
};
