import { useState, useCallback } from "react";
import Airtable from "airtable";
import airtableConfig from "../config";

var baseProject = new Airtable({ apiKey: airtableConfig.apiKey }).base(
  airtableConfig.project.db
);

const useProject = () => {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  const fetchData = useCallback((id_farmacia, status) => {
    const {
      table,
      view,
      parameterName,
      errorMessage,
      countOfProjects,
      fields,
      fieldsToParameters,
      statusFilters,
    } = airtableConfig.project;

    setData(null);
    setErrorMessage(null);
    setDataLoading(true);

    const statusFiltersLine = (() => {
      let str = "";
      statusFilters.forEach((st) => {
        str += `,{ESTADO_FORMULA}='${st}'`;
      });
      return str.substring(1);
    })();

    baseProject(table)
      .select({
        maxRecords: countOfProjects,
        view,
        filterByFormula: `AND(OR(${statusFiltersLine}),{${parameterName}}='${id_farmacia}')`,
        fields,
      })
      .eachPage(
        function page(records) {
          if (records.length) {
            const recordsToParameters = records.map((r) => {
              return fieldsToParameters(r?.fields);
            });
            setData(recordsToParameters);
          } else {
            setErrorMessage(errorMessage);
          }

          setDataLoading(false);
        },
        function done(err) {
          setDataLoading(false);
          if (err) {
            setErrorMessage(errorMessage);
            return;
          }
        }
      );
  }, []);

  return [fetchData, data, dataLoading, errorMessage];
};
export default useProject;
