import { useState, useEffect } from "react";

const dataCert_NO = {
  text: "¿Has ayudado, en una donación, con más de 100 euros?",
  subText:
    "Por normativa legal en materia de Prevención de Blanqueo de Capitales es obligatorio registrar a los donantes que realizan estas aportaciones o superiores.",
  subText2: null,
  textBtn: "Pincha AQUÍ",
  link: "https://airtable.com/shrpAxtw7Psl50RJz",
};
const dataCert_YES = {
  text: "Esta ONG permite la solicitud del Certificado de tu Donación.",
  subText:
    'De tus primeros 150 euros que dones se DESGRAVARÁN el 80% *. Tienes la oportunidad de "traer dinero del Estado a tu Barrio o Pueblo".',
  subText2: "* Puede variar según la Comunidad Autónoma.",
  textBtn: "SOLICITAR",
  link: "https://airtable.com/shrpAxtw7Psl50RJz",
};

const CertDonation = ({ currentProject }) => {
  const [data, setData] = useState(dataCert_NO);

  useEffect(() => {
    setData(
      currentProject && currentProject.cert_donation
        ? dataCert_YES
        : dataCert_NO
    );
  }, [currentProject]);

  return (
    <div className="row g-0 align-items-center px-4">
      <div className="col pe-3">
        <div className="donaciones-superiores">
          <div className="donaciones-superiores-title">{data.text}</div>
          <div className="donaciones-superiores-p">{data.subText}</div>
          {data.subText2 ? (
            <div className="donaciones-superiores-p">{data.subText2}</div>
          ) : null}
        </div>
      </div>
      <div className="col-auto text-end">
        <a
          href={data.link}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          {data.textBtn}
        </a>
      </div>
    </div>
  );
};

export default CertDonation;
