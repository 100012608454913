import bgHandsSrc from "../../../assets/img/bg-hands.webp";
import ValueGoal from "./valueGoal";
import Timer from "./timer";

const Goal = ({ currentProject }) => {
  return (
    <>
      <div
        className="objectives-help"
        style={{
          backgroundImage: "url(" + bgHandsSrc + ")",
        }}
      >
        <div className="row g-0 align-items-stretch">
          {currentProject ? (
            <>
              <div className="col-md-6">
                <ValueGoal
                  currentProject={currentProject}
                  goal={currentProject.goal}
                  apiKey={currentProject.apiKey}
                  dateInit={currentProject.dateInit}
                  dateEnd={currentProject.dateEnd}
                />
              </div>
              <div className="col-md-6">
                <Timer value={currentProject.dateEnd} />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">
                <div className="objectives-help-bg bg-1 auto-h" />
              </div>
              <div className="col-md-6">
                <div className="objectives-help-bg bg-2 auto-h" />
              </div>
              <div className="objectives-help_no-current-project">
                Próximo proyecto
                <br />
                en marcha
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Goal;
