// FARMACIA PARAMETERS
const parametersFarmacia = {
  id: "ID FARMACIA",
  name: "NOMBRE COMERCIAL",
  logo: "LOGO_FCIA",
  // url: "Web",
};

// PROJECT PARAMETERS
const parametersProject = {
  entity: "NOMBRE COMERCIAL",
  name: "NOMBRE DEL PROY. SOLIDARIO",
  description: "DESCRIPCION_PS",
  logo: "LOGO_ESAL",
  dateInit: "F. INICIO",
  dateEnd: "F. FIN",
  goal: "OBJ. RECAUDACION",
  recEnd: "REC. FINAL",
  status: "ESTADO_FORMULA",
  apiKey: "APIKEY_EXTRACTOR",
  cert_donation: "CERT_DONACION",
};

// AIRTABLE CONFIG
const airtableConfig = {
  apiKey: "patkySV3gB1TCs9Ok.a5a9c43a204e5e3be77089e1ea50fac5cf92e80e5588e240ad989577f608d4da",
  farmacia: {
    db: "apprECGBSfUC1yOkF",
    table: "BBDD_FARMACIAS",
    view: "APP_FARMACIAS_ACTIVAS",
    parameterName: "CODE",
    errorMessage: "El código no corresponde a ninguna farmacia activa.",
    fields: (() => {
      const list = [];

      for (let a in parametersFarmacia) {
        list.push(parametersFarmacia[a]);
      }
      return list;
    })(),
    fieldsToParameters: (recordFields) => {
      if (!recordFields) {
        return null;
      }
      let result = {};
      for (let a in parametersFarmacia) {
        const item = recordFields[parametersFarmacia[a]];
        if (item) {
          switch (a) {
            case "logo":
              result[a] = item[0]?.thumbnails?.large?.url;
              break;
            default:
              result[a] = item;
          }
        } else {
          result[a] = "";
        }
      }

      return result;
    },
  },
  project: {
    db: "appbAZ859aQTPycER",
    table: "SGTO_PROYECTOS",
    view: "APP_PROYECTOS",
    parameterName: "SELECCIONA LA FARMACIA",
    errorMessage: "No se encuentran proyectos asociados a esta farmacia.",
    countOfProjects: 16,
    fields: (() => {
      const list = [];

      for (let a in parametersProject) {
        list.push(parametersProject[a]);
      }
      return list;
    })(),
    fieldsToParameters: (recordFields) => {
      //console.log("recordFields", recordFields);
      if (!recordFields) {
        return null;
      }
      let result = {};
      for (let a in parametersProject) {
        const item = recordFields[parametersProject[a]];

        if (item) {
          switch (a) {
            case "logo":
              result[a] = item[0]?.thumbnails?.large?.url;
              break;
            case "entity":
            case "description":
            case "name":
            case "apiKey":
            case "cert_donation":
              result[a] = item[0] || "";
              break;

            default:
              result[a] = item;
          }
        } else {
          result[a] = "";
        }
      }
      return result;
    },
    statusFilters: ["Finalizado", "En Marcha"],
  },
};

export default airtableConfig;
